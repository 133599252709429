import './FeaturedListings.css';

import { useEffect } from "react";
import { getFeaturedListings } from "../../utilities/featuredListings-api";


import FeaturedList from "../../components/FeaturedList/FeaturedList";
import FeaturedListingForm from "../../components/FeaturedListingForm/FeaturedListingForm";


export default function FeaturedListings({ user, featuredListingItems, setFeaturedListingItems })
{   
    // Updates State when Featured Listings are editted or deleted
    useEffect(() => {
        const fetchUpdatedListing = async() => {
            try
            {
                const updatedListings = await getFeaturedListings();
                setFeaturedListingItems( updatedListings )
            }
            catch(err)
            {
                console.log("Error fetching updated Listing");
            }
        }
        fetchUpdatedListing();
    }, [])
    
    return (
        <main id="featuredListingsid" className="featuredlistings-main">
        <div className='featuredlistings-main-heading'>Featured Listings</div>
        {/* <FeaturedListingForm featuredListingItems={ featuredListingItems } setFeaturedListingItems={ setFeaturedListingItems } /> */}
        <div className='featuredlistings-container'>
            {featuredListingItems &&
                featuredListingItems.map(listing=>(
                    <FeaturedList key={ listing._id } user ={user} listing={listing} />
            ))}
        </div>
        </main>

    )
}