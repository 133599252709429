import "../../pages/FeaturedListings/FeaturedListings.css"

import { Link } from "react-router-dom";

import stockhouse from "../../data/stockhouse.jpg";

export default function ClassList({ user, listing })
{  
    return (
        <>
        <section className="featuredList-listing">
            <div className="featuredList-mainImage">
                <img src={listing.coverImg} alt="house" className="featuredList-mainImage-img" />
            </div>
            <div className="featuredList-value-price">${ listing.price }</div>
            <div className="featuredList-address">{ listing.address }</div>
            <div className="featuredList-status">{listing.status}</div>
            <div className="featuredList-brief-desc">
                <div className="featuredList-value-bed">{listing.beds } beds•</div>
                <div className="featuredList-value-baths">{ listing.baths } baths•</div>
                <div className="featuredList-value-sqfootage">{ listing.sqft } sq ft•</div>
                <div className="featuredList-value-type">{ listing.type }</div>
            </div>
            <div className="featuredList-mls">MLS # { listing.mls }</div>

            {listing.address ? <>
            <Link to={`/featured_listingsDetails?listingId=${listing._id}`} className="featuredList-edit-link" state={ {listing, user} } >See More</Link>
            </>: null}
        </section>
        </>
    )
}