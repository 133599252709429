import "./FeaturedListMore.css";

import {useEffect, useState} from "react";

import {useLocation, useNavigate} from "react-router-dom";

import {getUser} from "../../utilities/users-services";

import Navbar from "../Navbar/Navbar";

import MenuOverlay from "../../pages/MenuOverlay/MenuOverlay";

import { editFeaturedListing, deleteFeaturedListing } from "../../utilities/featuredListings-api";

export default function FeaturedListMore() {

    const location = useLocation();
    const {listing, user} = location.state;
    console.log("Listing: ", listing)
    const navigate = useNavigate();

    // Fill in listing state
    const [listingState, setListingState] = useState({
        name: listing.name,
        price: listing.price,
        address: listing.address,
        beds: listing.beds,
        baths: listing.baths,
        sqft: listing.sqft,
        desc: listing.desc,
        status: listing.status
    });

    const [error, setError] = useState("");

    // handle changes in form input fields
    function handleChange( evt )
    {
        setListingState({...listingState, [evt.target.name]: evt.target.value});
        setError("");
    }

    // handle form submission
    async function handleSubmit( evt )
    {
        evt.preventDefault();
        try {
            const editFeatListing = await editFeaturedListing( listing._id, listingState );
            // TODO: do we need to update listing in App.jsx???
            navigate('/');
        } catch {
            setError("Submition Failed - Please Try Again");
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const deleteListing = async () => {
        try {
            const response = await deleteFeaturedListing(listing._id);
            console.log('Response:', response);
            navigate('/');
        } catch (error) {
            console.error('Error deleting listing:', error);
            alert('An error occurred while deleting the listing');
          }
    }
    
    
      
    return(
        <>
        <Navbar />
        <section className="featuredListMore">
            <section className="featuredListMore-left">
                <img src={listing.coverImg} alt="house" className="featuredListMore-left-img" />
            </section>
            
            <section className="featuredListMore-right">
                <div className="featuredListMore-right-price">${listing.price}</div>
                <div className="featuredListMore-right-address">{listing.address}</div>
                <div className="featuredListMore-bed-bath-sqft-type">
                    <div className="featuredListMore-beds">{listing.beds} beds • </div>
                    <div className="featuredListMore-baths">{listing.baths} baths • </div>
                    <div className="featuredListMore-sqft">{listing.sqft} sq ft • </div>
                    <div className="featuredListMore-type">{listing.type}</div>
                </div>
                <div className="featuredListMore-mls">MLS # {listing.mls}</div>
                <div className="featuredListMore-desc">{listing.desc}</div>
                {user ?
                    <>
                    <button className="featuredList-delete" onClick={deleteListing}>Delete Listing</button>
                    <div className="editlisting-form-container">
                        <form onSubmit={handleSubmit}>
                            <label>Edit featured Listing</label>
                            <input
                                type="text"
                                name="name"
                                value={ listingState.name }
                                onChange={ handleChange }
                                required
                            />
                            <label>Price</label>
                            <input
                                type="number"
                                name="price"
                                value={ listingState.price }
                                onChange={ handleChange }
                                required
                            />
                            <label>Address</label>
                            <input
                                type="text"
                                name="address"
                                value={ listingState.address }
                                onChange={ handleChange }
                                required
                            />
                            <label>Baths</label>
                            <input
                                type="number"
                                name="baths"
                                value={ listingState.baths }
                                onChange={ handleChange }
                                required
                            />
                            <label>Bed</label>
                            <input
                                type="number"
                                name="beds"
                                value={ listingState.beds }
                                onChange={ handleChange }
                                required
                            />
                            <label>Square Footage</label>
                            <input
                                type="number"
                                name="sqFootage"
                                value={ listingState.sqFootage }
                                onChange={ handleChange }
                                required
                            />
                            <label>Description</label>
                            <input
                                type="text"
                                name="longDescription"
                                value={ listingState.desc }
                                onChange={ handleChange }
                                required
                            />
                            <button type="submit">Submit</button>
                        </form>
                        <p className="error-message">{ error }</p>
                    </div>
                    </>
                : null}
            </section>
        </section>
        <MenuOverlay />
        </>
    )
}