import "./AuthPage.css";

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import LoginForm from "../../components/LoginForm/LoginForm";

export default function AuthPage({user, setUser}) {
    const navigate = useNavigate();

    useEffect(() => {
      if (user) {
        navigate('/');
      }
    }, [user, navigate]);
  
    return (
      <div className="authpage">
        {!user && <LoginForm setUser={setUser} />}
      </div>
    );
}